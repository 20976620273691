<template>
  <div class="w-full flex align-center justify-center">
    <div
      class="relative w-3/4 2xl:w-3/6 grid lg:grid-cols-2 shadow-2xl rounded-xl bg-gray-50 mx-8 pr-4 py-6 grid-cols-1"
    >
      <div class="truncate flex flex-col gap-2">
        <a-icon
          type="frown"
          :style="{ fontSize: '32em', color: '#a3a3a3a2' }"
          class="md:relative md:right-1/4 hidden lg:block"
        />
        <span
          class="text-4xl text-gray-400 text-center font-bold opacity-70 right-1/3 lg:right-1/4 ml-0   relative hidden lg:block"
          > Servicio no <br> disponible</span
        >
      </div>
      <div
        class="relative flex flex-col justify-center align-center gap-4 box-border"
      >
        <a-icon type="api" :style="{ fontSize: '150px' }" />
        <div class="flex align-center justify-center">
          <Number number="5" />
          <Number number="0" />
          <Number number="3" />
        </div>
        <p class="text-blue-800 text-2xl font-bold px-4 box-border mt-4">
          {{ msg }} 
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      required: true,
      default:
        "En este momento Smartsupervision se encuentra en mantenimiento. Por favor, intentelo nuevamente más tarde.",
    },
    error: false,
  },
  created() {
    if (!this.error) {
      this.$router.push("/login");
    }
  },
};
</script>
